<template>
  <q-page class="bg-white no-wrap">
    <div class="code" :class="$store.getters.partner.slug" />
    <div class="container">
      <q-card square class="bg-white">
        <q-card-section :class="`bg-primary text-white text-uppercase`">
          {{ $t('where_are_you_travelling') }}
        </q-card-section>
        <q-card-section>
          <div class="ferry-form">
            <label for="routes-select">
              <q-icon left size="24px" name="fas fa-location" color="primary" />
            </label>
            <input
              id="routes-select"
              v-model="routesSearch"
              class="routes-select"
              placeholder="Choose route"
              autocomplete="off"
              @focus="() => showRoutesList = true"
            >

            <button id="swap-button" aria-label="swap" @click="reverseRoute">
              <q-icon size="24px" name="fa fa-sort-alt" color="primary" />
            </button>

            <!-- Return box -->
            <q-icon v-if="showReturn" left size="24px" name="fas fa-route" color="primary" />
            <input
              v-if="showReturn"
              :value="routeReturn.name"
              class="routes-select"
              autocomplete="off"
              disabled
            >

            <!-- Selection list -->
            <transition name="dropdown">
              <div v-if="showRoutesList" class="list">
                <q-scroll-area class="list-inner">
                  <q-list id="routes-list" no-border>
                    <q-item
                      v-for="route in filteredRoutes"
                      :key="route.id"
                      clickable
                      data-testid="route"
                      @click="setRoute(route)"
                    >
                      <q-item-section avatar>
                        <q-avatar icon="fas fa-ship" text-color="primary" />
                      </q-item-section>
                      <q-item-section>
                        {{ route.name }}
                      </q-item-section>
                    </q-item>
                    <q-btn
                      color="primary"
                      class="close"
                      :label="$t('close')"
                      @click="() => showRoutesList = false"
                    />
                  </q-list>
                </q-scroll-area>
              </div>
            </transition>

            <!-- Radio buttons -->
            <div class="oneway-return">
              <label><input v-model="showReturn" type="radio" :value="false">{{ $tc('one_way_return', 1) }}</label>
              <label><input v-model="showReturn" type="radio" :value="true">{{ $tc('one_way_return', 2) }}</label>
            </div>
          </div>

          <m-leave-now
            class="span-three"
            :departing="params.depart"
            :returning="params.return"
            :show-return="showReturn"
            :show-transport-prefs="false"
            :show-passengers="passengers"
            :date-options="dateOptions"
            :return-date-options="returnDateOptions"
            :date-only="true"
            :disabled="$v.$invalid"
            :railcard-options="concessions"
            @depart="val => setDepart(val)"
            @return="val => params.return = val"
            @passengers="val => passengers = val"
            @concessions="val => concessions = val"
            @navigateDate="updateAvailability"
            @submit="submit"
          >
            <div id="info">
              {{ $t('ferry.timetable') }}
              <a href="https://northlinkferries.co.uk/booking-info/timetables" target="_blank">timetables.</a>
            </div>
            <div v-if="!user.has_northlink">
              {{ $t('ferry.membership.have_an_id') }}
              <router-link to="#/user-settings/ferry">
                {{ $t('login.login_here') }}
              </router-link>
            </div>
            <div v-else>
              <span class="fas fa-check text-green" /> {{ $t('ferry.membership.signed_in') }}
            </div>
            <m-unified-users v-model="people" class="inline-card" />
          </m-leave-now>
        </q-card-section>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import 'styles/ondemand.styl'
import { required, minValue } from 'vuelidate/lib/validators'
import { routes as getRoutes, availability as getAvailability } from 'api/ferry'
import { MLeaveNow, MUnifiedUsers } from 'components/'
import date from 'utils/date-time'
import loading from 'utils/loading'
import { handleErrors } from 'utils/utils'
const { newDate, addToDate, toCivilDate, getDateDiff, getDiff } = date

export default {
  components: { MLeaveNow, MUnifiedUsers },
  data () {
    return {
      routes: [],
      showPassengerForm: false,
      showRoutesList: false,
      params: {
        adults: 1,
        children: 0,
        infants: 0,
        adult_concessions: 0,
        child_concessions: 0,
        route_code: null,
        depart: toCivilDate(newDate()),
        return: toCivilDate(addToDate(newDate(), { days: 1 })),
        user: null,
        requester: null,
        concessions: {
          adult: 0,
          child: 0
        },
        use_account: 0
      },
      routesSearch: '',
      showReturn: false,
      availability: null,
      months: 1
    }
  },
  computed: {
    user () {
      return this.$store.getters.userLookup
    },
    filteredRoutes () {
      const sanitisedSearch = this.routesSearch.toLowerCase()
      const newRoutes = this.routes.filter(({ origin, destination }) => {
        return origin.name.toLowerCase().startsWith(sanitisedSearch) || destination.name.toLowerCase().startsWith(sanitisedSearch)
      })
      return newRoutes
    },
    people: {
      get () {
        return {
          user: this.params.traveller,
          requester: this.params.requester
        }
      },
      set (val) {
        this.params.requester = val.requester
        this.params.user = val.traveller
      }
    },
    routeReturn () {
      if (!this.params.route_code) return ''
      const oldRoute = this.params.route_code
      const from = oldRoute.slice(0, 2)
      const to = oldRoute.slice(-2)
      const newRouteId = to + from
      const [newRoute] = this.routes.filter(route => route.id === newRouteId)
      return newRoute
    },
    passengers: {
      get () {
        const { adults, children, infants } = this.params
        return { adults, children, infants }
      },
      set (val) {
        const { adults, children, infants } = val
        this.params.adults = adults
        this.params.children = children
        this.params.infants = infants
      }
    },
    concessions: {
      get () {
        const options = []
        if (this.params.concessions.adult < this.params.adults) {
          options.push({ code: 'adult', name: 'Adult Concession' })
        }
        if (this.params.concessions.child < this.params.children) {
          options.push({ code: 'child', name: 'Child Concession' })
        }

        return options
      },
      set (options) {
        const concessions = {
          adult: 0,
          child: 0
        }
        options.forEach(({ code }) => { concessions[code] += 1 })
        this.params.concessions = concessions
      }
    }
  },
  validations: {
    params: {
      adults: { required, minValue: minValue(1) },
      route_code: { required }
    }
  },
  async beforeMount () {
    this.$store.commit('ondemand/clear')
    const { data } = await getRoutes()
    this.routes = data
    if (this.user.has_northlink) this.params.use_account = 1
  },
  methods: {
    setRoute (route) {
      this.params.route_code = route.id
      this.routesSearch = route.name
      this.showRoutesList = false
      this.setAvailability()
    },
    reverseRoute () {
      if (!this.params.route_code) return ''
      const newRoute = this.routeReturn
      this.params.route_code = newRoute.id
      this.routesSearch = newRoute.name
    },
    dateOptions (val) {
      if (!this.availability) return false
      const formatVal = val.replace(/\//g, '-')
      return this.availability[this.params.route_code][formatVal]?.available && true && (getDiff(formatVal, 'days').values.days > 0)
    },
    returnDateOptions (val) {
      if (!this.availability) return false
      const formatVal = val.replace(/\//g, '-')
      return this.availability[this.routeReturn.id][formatVal]?.available && formatVal >= toCivilDate(this.params.depart) && (getDiff(formatVal, 'days').values.days > 0)
    },
    setDepart (val) {
      if (val) {
        this.params.depart = val
        this.params.return = toCivilDate(addToDate(val, { days: 1 }))
      }
    },
    async setAvailability () {
      loading.start({
        message: this.$t('loading.checking.timetable'),
        partnerSlug: this.$store.getters.partner.slug,
        spinnerColor: this.$store.getters.partner.hex
      })
      try {
        const { route_code, depart } = this.params
        const { data } = await getAvailability(route_code, { months: this.months })
        this.availability = data.availability

        // If current depart date is not available
        // Set depart date to next available
        if (!this.dateOptions(depart)) {
          const departAvailability = this.availability[route_code]
          const options = Object.keys(departAvailability)
            .filter(d => {
              const isAvailable = departAvailability[d].available
              const { days } = getDateDiff(d, depart, 'days')
              return isAvailable && Math.floor(days) >= 0
            })
          this.setDepart(options[0])
        }
        // Then do the same for return date
        if (!this.returnDateOptions(this.params.return)) {
          const returnAvailability = this.availability[this.routeReturn.id]
          const options = Object.keys(returnAvailability)
            .filter(d => {
              const isAvailable = returnAvailability[d].available
              const { days } = getDateDiff(d, this.params.return, 'days')
              return isAvailable && days >= 0
            })
          if (options.length > 0) this.params.return = options[0]
        }
      } catch (err) {
        handleErrors(err)
      } finally {
        loading.stop()
      }
    },
    updateAvailability (val) {
      const [, thisMonth] = this.params.depart.split('-')
      const { month } = val
      const months = 1 + (month - thisMonth)
      if (months > this.months) {
        this.months = 1 + (month - thisMonth)
        if (this.params.route_code) {
          this.setAvailability()
        }
      }
    },
    submit () {
      const parameters = { ...this.params }

      if (!this.showReturn) delete parameters.return
      else parameters.return = toCivilDate(this.params.return)

      parameters.depart = toCivilDate(this.params.depart)

      parameters.adult_concessions += this.params.concessions.adult
      parameters.adults -= this.params.concessions.adult
      parameters.child_concessions += this.params.concessions.child
      parameters.children -= this.params.concessions.child

      this.$store.dispatch('ondemand/stash', { params: parameters })
      this.$router.push({ name: 'ondemand-ferry-results' })
    }
  }
}
</script>

<style lang="stylus" scoped>
a
  text-decoration underline

#info
  margin 8px 0

.mobile
  .code
    display none
  .q-card
    width 100vw
    box-shadow none

.column.container
  margin-top -64px

.q-card
  width 80vw
  max-width 600px

.center
  justify-content center
  display flex

.q-page
  display flex
  width 100%
  flex-direction column
  align-items center
  background white

.ferry-form
  width 100%
  display grid
  grid-template-columns 36px auto 36px
  grid-auto-rows auto
  align-items center

.routes-select
  width 100%
  border 1px solid #ccc
  border-radius 20px
  height 56px
  padding 6px
  margin 8px 0
  position relative
  line-height 28px

#routes-list
  height auto

#swap-button
  background white
  border none
  width 24px
  height 24px
  margin-left 12px
  grid-row 1 / 3
  grid-column 3 / span 1

.close
  width 100%
  position sticky

@keyframes dropdown {
  from { height: 0px }
  to { height: max-height }
}

.dropdown-enter-active
  transition height 0.5s

.dropdown-leave-active
  transition height 0.5s

.dropdown-enter-to
  animation dropdown 0.5s

.dropdown-leave-to
  animation dropdown 0.5s reverse

.list
  position: absolute;
  background: white;
  z-index: 100;
  width: 90%
  height: 60vh
  max-width 420px
  left: 5%
  top 90px
  box-shadow: 0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12);

.list-inner
  height: 100%

.span-three
  grid-column 1 / span 3

.oneway-return
  grid-column 1 / span 3
  display grid
  grid-template-columns 1fr 1fr
  align-items center
  label
    display flex
    align-items center
    justify-content center
    color black

input
  &[type="radio"]
    margin 12px
    width: 20px;
    height: 20px;
    border-radius 15px
    border: 2px solid #666
    &:checked
      border: 2px solid var(--q-color-primary)
      &:after
        width: 14px;
        height: 14px;
        bottom 2px
        left 1px
        border-radius: 15px;
        position: relative;
        background-color: var(--q-color-primary)
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white

  .timetable
    text-decoration underline !important

// Reset some q-card styles since this will be a nested card
.inline-card
  >>> .q-card
    box-shadow none
    margin 0
    padding 0
    width 100%
  >>> .q-card__section
    padding-inline 0
</style>
